import { useAuth } from "../containers/hooks";

const getFilenameFromUrl = (url: string, defaultName: string) => {
  return url.split("/").pop() || defaultName;
};

export function useDownloadFile() {
  const { token } = useAuth();

  return async function (url: string, defaultName: string) {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const statusCode = response.status;
    if (statusCode !== 200) {
      throw new Error(`Request failed with status code ${statusCode}`);
    }
    const fileName = getFilenameFromUrl(url, defaultName);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}
