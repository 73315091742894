import { trpc } from "../../../../../config/trpc";

export default function useCutGene(
  constructId: string | undefined,
  assayId: string | undefined,
) {
  const utils = trpc.useUtils();
  const { mutate: cutGeneMutation, isPending } =
    trpc.assay.steps.gene.cut.useMutation({
      onSuccess() {
        utils.assay.get.invalidate(assayId);
        utils.construct.get.invalidate({ id: constructId });
      },
    });

  const designGeneOligos = () => {
    if (!constructId) {
      return;
    }
    cutGeneMutation({ constructId });
  };

  return { designGeneOligos, isPending };
}
