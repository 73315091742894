export enum AccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum NotificationChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
  TEAMS = "TEAMS",
}

export enum OrganizationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ObjectMembership {
  Assay = "Assay",
  Construct = "Construct",
  Hamilton = "Hamilton",
  Syntax = "Syntax",
}

export enum AuthorizationOwner {
  Member = "Member",
  Team = "Team",
}

export enum ConstructType {
  Gene = "Gene",
  OligoSet = "OligoSet",
}

export enum ConstructDownloadFormat {
  CSV = "CSV",
  FASTA = "FASTA",
}

export enum StepType {
  HamiltonAssembly = "HamiltonLigation",
  OligoSynthesis = "OligoSynthesis",
}

export enum PlateSize {
  S384 = "384",
  S96 = "96",
}

export enum Normalization {
  Lowest = "Lowest",
  None = "None",
  Target = "Target",
}

export enum Purity {
  Difficult = "Difficult",
  Extreme = "Extreme",
  Normal = "Normal",
  Unknown = "Unknown",
}

export enum WellErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum RunState {
  Completed = "Completed",
  Failed = "Failed",
  Loaded = "Loaded",
  Printing = "Printing",
  Queued = "Queued",
  Unknown = "Unknown",
  Washing = "Washing",
}

export enum PlateErrorType {
  Biosecurity = "Biosecurity",
  Cycles = "Cycles",
  Modifiers = "Modifiers",
}

export enum PlateErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum WellErrorType {
  Biosecurity = "Biosecurity",
  Click = "Click",
  Length = "Length",
  Purity = "Purity",
  UnknownNucleotide = "UnknownNucleotide",
}

export enum ModificationStatus {
  Acceptable = "Acceptable",
  Low = "Low",
  NonQuantifiable = "NonQuantifiable",
  None = "None",
  Successful = "Successful",
}

export enum RunReportFileFormat {
  CSV = "CSV",
  JSON = "JSON",
  PDF = "PDF",
}

export enum InstrumentStatus {
  Available = "Available",
  Busy = "Busy",
  Unavailable = "Unavailable",
}

export enum ChillerDoorStatus {
  Closed = "Closed",
  Open = "Open",
  Unknown = "Unknown",
}

export enum ChillerTrayStatus {
  Missing = "Missing",
  Present = "Present",
}

export enum ChillerSippersStatus {
  Lowered = "Lowered",
  Moving = "Moving",
  Raised = "Raised",
}

export enum DoorStatus {
  ClosedLocked = "ClosedLocked",
  ClosedUnlocked = "ClosedUnlocked",
  Open = "Open",
  Unknown = "Unknown",
}

export enum NotificationType {
  RunCompleted = "RunCompleted",
  RunCreated = "RunCreated",
}

export enum PQA {
  HighPlex = "HighPlex",
  Labels = "Labels",
  Standard = "Standard",
}

export enum Role {
  Administrator = "Administrator",
  OrganizationManager = "OrganizationManager",
  Support = "Support",
}
