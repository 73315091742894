import { useToast } from "../../../../../components/ui/use-toast";
import { ConstructType } from "../../../../../config/enums";
import { trpc } from "../../../../../config/trpc";
import { useInvalidateTags } from "../../../../../containers/user/hooks";
import type { ConstructFromTRPC } from "../constructTypes";
import type { EditConstructForm } from "../edit-construct";

export const useUpdateConstruct = (
  construct: ConstructFromTRPC | undefined,
) => {
  const { toast } = useToast();
  const invalidateTags = useInvalidateTags();

  const constructId = construct?.id;

  const { mutate: updateConstruct } = trpc.construct.update.useMutation({
    onError(err) {
      toast({
        description: err.message,
        title: "Error when updating construct",
        variant: "destructive",
      });
    },
    onSuccess(_, input) {
      toast({
        description: `Your ${input.gene ? "gene" : "oligo set"} has been updated`,
        title: "Construct updated",
      });
      if (JSON.stringify(input.tags) !== JSON.stringify(construct?.tags)) {
        invalidateTags();
      }
    },
  });

  const handleUpdateConstruct = (data: EditConstructForm) => {
    if (!constructId) {
      return;
    }
    const { description, details, name, tags } = data;
    const isGene = details.type === ConstructType.Gene;
    const isOligoSet = details.type === ConstructType.OligoSet;
    const oligosToSend = (isOligoSet ? details.oligos : []).map((oligo) => ({
      id: oligo.id,
      name: oligo.name,
      placement: oligo.wellHint,
      sequence: oligo.sequence,
    }));

    return updateConstruct({
      constructId,
      description,
      gene: isGene
        ? {
            sequence: details.sequence,
            vector: details.vector
              ? {
                  insertPosition: details.vector.insertPosition,
                  sequence: details.vector.sequence,
                }
              : undefined,
          }
        : undefined,
      name,
      oligos: isOligoSet ? oligosToSend : undefined,
      tags,
    });
  };

  return handleUpdateConstruct;
};
