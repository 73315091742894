import { Heading } from "@radix-ui/themes";
import {
  AlertTriangle,
  Brain,
  ChevronDownIcon,
  Download,
  Save,
  Trash2,
} from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { Oligo } from "./components/types";
import type { ConstructFromTRPC, OligoFromTRPC } from "./constructTypes";
import type { EditConstructForm } from "./edit-construct";
import EditConstruct from "./edit-construct";
import useCutGene from "./hooks/useCutGene";
import { useDownloadConstruct } from "./hooks/useDownloadConstruct";
import useRemoveGeneOligos from "./hooks/useRemoveGeneOligos";
import { useUpdateConstruct } from "./hooks/useUpdateConstruct";

import { Button } from "../../../../components/ui/button";
import {
  ObjectMembership,
  ConstructDownloadFormat,
  ConstructType,
} from "../../../../config/enums";
import { trpc } from "../../../../config/trpc";
import OwnersBlock from "../../../instrument/components/owners-block";
import { OrganizationRoutes } from "../../organization-routes";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const parseOligos =
  (isAssayArchived: boolean) =>
  (oligo: OligoFromTRPC): Oligo => {
    return {
      id: oligo.id,
      locked: oligo.locked || isAssayArchived,
      name: oligo.name,
      sequence: oligo.sequence,
      wellHint: oligo.platePosition ?? null,
    };
  };

const getDefaultConstructDetails = (
  construct: ConstructFromTRPC | undefined,
): EditConstructForm["details"] => {
  const isAssayArchived = !!construct?.assay?.archived;
  if (construct?.constructType === ConstructType.Gene) {
    return {
      locked: construct.gene?.locked ?? false,
      oligos: (construct.oligos ?? [])
        .map(parseOligos(isAssayArchived))
        .map((o) => ({ ...o, locked: true })), // Always lock oligos for genes
      sequence: construct.gene?.sequence ?? "",
      type: ConstructType.Gene,
      vector: construct.gene?.vector
        ? {
            insertPosition: construct.gene.vector.insertPosition ?? 0,
            sequence: construct.gene.vector.sequence ?? "",
          }
        : undefined,
    };
  }
  if (construct?.constructType === ConstructType.OligoSet) {
    return {
      oligos: (construct.oligos ?? []).map(parseOligos(isAssayArchived)),
      type: ConstructType.OligoSet,
    };
  }
  return {
    oligos: [],
    type: ConstructType.OligoSet,
  };
};

const getDefaultValues = (
  construct: ConstructFromTRPC | undefined,
): EditConstructForm | undefined => {
  if (!construct) {
    return undefined;
  }
  return {
    assayId: construct?.assayId ?? "",
    description: construct?.description ?? "",
    details: getDefaultConstructDetails(construct),
    name: construct?.name ?? "",
    tags: construct?.tags ?? [],
  };
};

const downloadFormats = Object.values(ConstructDownloadFormat);

export default function Construct() {
  const navigate = useNavigate();
  const { constructId } = useParams();
  const handleDownloadConstruct = useDownloadConstruct(constructId);
  const { data: construct, isPending: isConstructQueryPending } =
    trpc.construct.get.useQuery(
      {
        id: constructId ?? "",
      },
      {
        enabled: !!constructId,
      },
    );

  const { data: constructOwners } = trpc.account.authorization.owners.useQuery(
    {
      id: constructId ?? "",
      subjectType: ObjectMembership.Construct,
    },
    {
      enabled: !!constructId,
    },
  );

  const defaultConstructValues = useMemo(
    () => getDefaultValues(construct),
    [construct],
  );

  const [didChange, setDidChange] = useState<boolean>(false);

  const isGene = construct?.constructType === ConstructType.Gene;
  const assay = construct?.assay;
  const isArchived = construct?.isArchived ?? false;
  const assayName = assay?.name ?? "";
  const assayId = construct?.assayId ?? undefined;

  const updateConstruct = useUpdateConstruct(construct);

  const { designGeneOligos, isPending: isDesignPending } = useCutGene(
    constructId,
    assayId,
  );

  const { removeGeneOligos, isPending: isRemovePending } = useRemoveGeneOligos(
    constructId,
    assayId,
  );

  const handleUpdate = (data: EditConstructForm) => {
    updateConstruct(data);
  };

  if (!constructId || isConstructQueryPending) {
    return null;
  }

  return (
    <div className="space-y-4">
      <EditConstruct
        defaultValues={defaultConstructValues}
        onChange={setDidChange}
        onSave={handleUpdate}
      >
        <div className="flex flex-row items-center justify-between">
          <div>
            <div className="flex flex-row space-x-1">
              <Heading>Construct</Heading>
              {isArchived && (
                <p className="flex flex-row space-x-1 text-red-500">
                  <AlertTriangle />
                  <span>construct is archived</span>
                </p>
              )}
            </div>
            {assayName && (
              <div className="flex flex-row items-center space-x-1">
                <p>Belongs to assay</p>
                <Button
                  onClick={() => {
                    navigate(
                      OrganizationRoutes.ASSAY.replace(
                        ":assayId",
                        construct?.assayId ?? "",
                      ),
                    );
                  }}
                  variant={"ghost"}
                >
                  <p className="italic">{assayName}</p>
                </Button>
              </div>
            )}
          </div>

          <div className="flex flex-row space-x-2">
            <OwnersBlock
              objectId={constructId}
              owners={constructOwners}
              subject={ObjectMembership.Construct}
            />
            <div className="relative inline-flex">
              <Button
                onClick={() =>
                  handleDownloadConstruct(
                    isGene
                      ? ConstructDownloadFormat.FASTA
                      : ConstructDownloadFormat.CSV,
                  )
                }
                variant={"outline"}
              >
                <span>Download</span>
                <Download />
              </Button>

              <DropdownMenu>
                <DropdownMenuTrigger
                  asChild
                  className="border bg-white px-2 py-2"
                >
                  <Button variant={"ghost"}>
                    <ChevronDownIcon />
                  </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent align="end">
                  {downloadFormats.map((format) => {
                    const isDisabled =
                      isGene && format === ConstructDownloadFormat.CSV;
                    return (
                      <DropdownMenuItem className="p-0" key={format}>
                        <Button
                          className="w-full font-normal"
                          disabled={isDisabled}
                          onClick={() => handleDownloadConstruct(format)}
                          size="sm"
                          variant="ghost"
                        >
                          {format}
                        </Button>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {defaultConstructValues &&
              defaultConstructValues.details.type === ConstructType.Gene &&
              assayName && (
                <>
                  <Button
                    className="space-x-1"
                    id="design-oligos"
                    isLoading={isDesignPending}
                    onClick={designGeneOligos}
                    type="button"
                    variant={"outline"}
                  >
                    <span>Design oligos</span>
                    <Brain />
                  </Button>
                  {(defaultConstructValues.details.oligos?.length ?? 0) > 0 && (
                    <Button
                      className="space-x-1"
                      id="design-oligos"
                      isLoading={isRemovePending}
                      onClick={removeGeneOligos}
                      type="button"
                      variant={"outline"}
                    >
                      <span>Delete oligos</span>
                      <Trash2 />
                    </Button>
                  )}
                </>
              )}
            <Button
              aria-label="Edit construct"
              className="flex flex-row items-center space-x-1"
              disabled={!didChange}
              type="submit"
            >
              <span>{didChange ? "Save changes" : "No changes"}</span>
              <Save />
            </Button>
          </div>
        </div>
      </EditConstruct>
    </div>
  );
}
