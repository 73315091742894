import { trpc } from "../../../../config/trpc";

export const useActivateOrDeactivateOrgMember = (
  id: string,
  activate: boolean,
) => {
  const utils = trpc.useUtils();
  const { mutate: activateMember } =
    trpc.account.organizationUser.activate.useMutation({
      onSuccess() {
        utils.account.organization.listMembers.invalidate();
      },
    });

  const { mutate: deactivateMember } =
    trpc.account.organizationUser.deactivate.useMutation({
      onSuccess() {
        utils.account.organization.listMembers.invalidate();
      },
    });

  if (activate) {
    return () => {
      activateMember(id);
    };
  }
  return () => {
    deactivateMember(id);
  };
};
