import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export default function useRemoveGeneOligos(
  constructId: string | undefined,
  assayId: string | undefined,
) {
  const { toast } = useToast();
  const utils = trpc.useUtils();
  const { mutate: removeOligos, isPending } =
    trpc.assay.steps.gene.removeDesignedOligos.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error removing oligos",
          variant: "destructive",
        });
      },
      onSuccess() {
        utils.assay.get.invalidate(assayId);
        utils.construct.get.invalidate({ id: constructId });
      },
    });

  const removeGeneOligos = () => {
    if (!constructId) {
      return;
    }
    removeOligos({ constructId });
  };

  return { isPending, removeGeneOligos };
}
