import { getQueryKey } from "@trpc/react-query";
import { ArrowRight, Save, Trash } from "lucide-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AddPlate } from "./add-plate";
import LeaveWarning from "./leave-warning";
import { WorkflowActions } from "./reducer";
import type { Plate } from "./types";
import { preparePlatesForAPI } from "./utils";
import { useWorkflowBuildContext } from "./workflow-build-context";
import WorkflowOligos from "./workflow-oligos";
import { WellWorkflow } from "./workflow-well";

import { Button } from "../../../../../components/ui/button";
import { Input } from "../../../../../components/ui/input";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../../../../components/ui/resizable";
import { PlateSize } from "../../../../../config/enums";
import type { AssayTRPC } from "../../../../../config/trpc";
import { queryClient, trpc } from "../../../../../config/trpc";
import { cn } from "../../../../../lib/utils";
import { useGetKitLabels } from "../../../../admin/organizations/organization/components/useGetEnvironmentKits";
import { useKitIdsToSizeMap } from "../../../../settings/organization-settings/hooks";
import { DISPLAY_COLUMNS_AND_ROWS } from "../components/plate/display";
import { getColumns, getRows } from "../components/useGetAllWellsById";

export default function WorkflowBuild({ assay }: { assay: AssayTRPC }) {
  const {
    assayId,
    dispatchWorkflowChange,
    isWorkflowChanged,
    kitToAdd,
    oligoToWellsMap,
    setStoredWorkflowPlates,
    workflow,
  } = useWorkflowBuildContext();
  const noRemainingOligos = workflow.oligos.every((o) =>
    oligoToWellsMap.has(o.id),
  );

  const kitLabeler = useGetKitLabels();
  const kitToMap = useKitIdsToSizeMap();

  const handleRemovePlate = (plateId: string) => {
    dispatchWorkflowChange({
      payload: {
        plateIndex: workflow.plates.findIndex((plate) => plate.id === plateId),
      },
      type: WorkflowActions.REMOVE_PLATE,
    });
  };

  const handleChangePlateName = (plateId: string, name: string) => {
    dispatchWorkflowChange({
      payload: {
        name,
        plateIndex: workflow.plates.findIndex((plate) => plate.id === plateId),
      },
      type: WorkflowActions.RENAME_PLATE,
    });
  };

  const { mutate: assignOligoToPlates, isPending } =
    trpc.assay.steps.assignOligos.useMutation({
      onSuccess(_, variables) {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.assay.get),
        });
        setStoredWorkflowPlates(variables.plates as Plate[]);
      },
    });

  const storeChanges = () => {
    assignOligoToPlates({
      assayId,
      plates: preparePlatesForAPI(workflow.plates),
    });
  };

  return (
    <div className="rounded-lg border p-2">
      <LeaveWarning shouldDisplayWarning={isWorkflowChanged} />
      <h2 className="font-bold">Oligo assignment</h2>
      <ResizablePanelGroup direction="horizontal">
        <DndProvider backend={HTML5Backend}>
          <ResizablePanel className="p-2" defaultSize={45} minSize={20}>
            <div className="flex flex-row justify-between">
              <h3 className="font-bold">Oligos</h3>
              <Button
                disabled={noRemainingOligos}
                onClick={() => {
                  dispatchWorkflowChange({
                    payload: {
                      defaultKit: kitToAdd,
                      defaultSize: kitToMap.get(kitToAdd) ?? PlateSize.S96,
                    },
                    type: WorkflowActions.AUTO_ASSIGN,
                  });
                }}
                variant={"secondary"}
              >
                <span>Assign remaining</span>
                <ArrowRight />
              </Button>
            </div>
            <WorkflowOligos />
          </ResizablePanel>
          <ResizableHandle />
          <ResizablePanel
            className="flex flex-col p-2"
            defaultSize={55}
            minSize={30}
          >
            <div className="mb-2 flex flex-row justify-between ">
              <h3 className="font-bold">Plates</h3>
              <Button
                className="w-[160px] space-x-1"
                disabled={assay.archived || !isWorkflowChanged}
                isLoading={isPending}
                onClick={storeChanges}
              >
                <span>Save workflow</span>
                <Save />
              </Button>
            </div>
            <div className="max-h-[80vh] space-y-4 overflow-scroll ">
              {workflow.plates.map((plate, plateIndex) => {
                const columns = getColumns(plate.size);
                const rows = getRows(plate.size);
                const is96 = plate.size === PlateSize.S96;
                const locked = plate.locked;
                return (
                  <div
                    className={cn(
                      "flex flex-col space-y-1 rounded-lg border p-1 shadow-md",
                      locked && "opacity-50",
                    )}
                    key={plate.id}
                  >
                    <div className="flex flex-row items-center justify-between space-x-2">
                      <Input
                        disabled={locked}
                        id="plate-name"
                        onChange={(e) =>
                          handleChangePlateName(plate.id, e.target.value)
                        }
                        value={plate.name}
                      />
                      <p className="text-nowrap">{kitLabeler(plate.kit)}</p>
                      <Button
                        disabled={locked}
                        onClick={() => handleRemovePlate(plate.id)}
                        variant={"outline"}
                      >
                        <Trash />
                      </Button>
                    </div>
                    <div
                      className={cn(
                        `grid-cols-${DISPLAY_COLUMNS_AND_ROWS[plate.size].columns}  grid h-fit grow auto-cols-fr grid-flow-col gap-1 rounded-lg border p-2`,
                        is96 ? "text-sm" : "text-xs",
                      )}
                    >
                      <div
                        className={`grid-rows-${DISPLAY_COLUMNS_AND_ROWS[plate.size].rows} grid auto-rows-fr gap-1`}
                      >
                        <div />
                        {rows.map((r) => (
                          <div
                            className="flex items-center justify-center"
                            key={r}
                          >
                            {r}
                          </div>
                        ))}
                      </div>
                      {columns.map((column) => {
                        return (
                          <div
                            className={cn(
                              `grid-rows-${DISPLAY_COLUMNS_AND_ROWS[plate.size].rows} grid auto-rows-fr gap-1 rounded-lg`,
                            )}
                            key={column}
                          >
                            <>
                              <div className="flex items-center justify-center">
                                {column}
                              </div>
                              {rows.map((row) => {
                                const index = `${row}${column}`;
                                const well = plate.wells.find(
                                  (w) => w.index === index,
                                );
                                if (!well) {
                                  return null;
                                }

                                return (
                                  <WellWorkflow
                                    key={index}
                                    plateId={plate.id}
                                    plateIndex={plateIndex}
                                    well={well}
                                  />
                                );
                              })}
                            </>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <AddPlate dispatchWorkflowChange={dispatchWorkflowChange} />
          </ResizablePanel>
        </DndProvider>
      </ResizablePanelGroup>
    </div>
  );
}

/*
TODO
build workflow only from here
- handle well failure -> oligos have to be assigned again: create new plate. no workflow deps for now.

DESIGN
Orders
  Order content: oligo plates, genes
  Order oligos.
Gene design = new step. When creating an assay, gene design steps are created in the workflow.
  Click on design to generate oligos of the gene.
  For now, go to construct and click on "generate oligos"
Plates
  Map oligos into plates
  Analysis on each plate
  Retry some wells -> assign oligos to new plates
Customer plate: step for each order plate.


In an assay. Workflow:
(N) Order(s) -> synthesis plates -> analysis -> oligo plates

Change constructs -> they become orders.

*/
