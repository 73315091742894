import { z } from "zod";

import { Status_MachineWash_Type } from "../../generated/protobuf_gen/definitions/messages/process/Status";

export enum TagObjectType {
  Assay = "Assay",
  Construct = "Construct",
  Instrument = "Instrument",
}

export enum InstrumentHardwareVersion {
  V0 = "102",
  V1 = "201",
}

export enum PrimerType {
  ASSEMBLY = "ASSEMBLY",
  ERROR_CORRECTION = "ERROR_CORRECTION",
  NONE = "NONE",
  VECTOR = "VECTOR",
}

export enum StepType {
  HamiltonAssembly = "HamiltonLigation",
  OligoSynthesis = "OligoSynthesis",
}

export enum PlateSize {
  S384 = "384",
  S96 = "96",
}

export enum Purity {
  Difficult = "Difficult",
  Extreme = "Extreme",
  Normal = "Normal",
  Unknown = "Unknown",
}

export enum PlateErrorType {
  Biosecurity = "Biosecurity",
  Cycles = "Cycles",
  Modifiers = "Modifiers",
}

export enum PlateErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export type PlateError = {
  message: string;
  severity: PlateErrorSeverity;
  type: PlateErrorType;
};

export type CouplingRate = {
  coupling: string;
  rate: number;
};

export enum WellErrorType {
  Biosecurity = "Biosecurity",
  Click = "Click",
  Length = "Length",
  Purity = "Purity",
  UnknownNucleotide = "UnknownNucleotide",
}

export enum WellErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export type WellError = {
  message: string;
  severity: WellErrorSeverity;
  type: WellErrorType;
};

const estimatedRemainingTimeSchema = z
  .object({
    lastUpdate: z.date(),
    seconds: z.number(),
  })
  .optional();

const statusPhaseSchema = z.object({
  description: z.string(),
  estimatedRemainingTime: estimatedRemainingTimeSchema,
  label: z.string(),
  startTime: z.date().optional(),
  timeToNextTouchpoint: estimatedRemainingTimeSchema,
});

export enum RunStage {
  Completed = "completed",
  Failed = "failed",
  Loaded = "loaded",
  Printing = "printing",
  Queued = "queued",
  Unknown = "unknown",
  Washing = "washing",
}

const runProgressSchema = z.object({
  errors: z.array(
    z.object({
      description: z.string(),
      location: z.string(),
      mitigation: z.string(),
      type: z.string(),
      unit: z.string(),
    }),
  ),
  initialPhases: z.array(statusPhaseSchema).nullable(),
  stage: z.discriminatedUnion("step", [
    z.object({
      step: z.literal(RunStage.Queued),
    }),
    z.object({
      seconds: z.number(),
      step: z.literal(RunStage.Unknown),
    }),
    z.object({
      reason: z.object({
        message: z.string(),
        type: z.enum([
          "Aborted",
          "Error",
          "NoInstrumentUpdate",
          "InstrumentVersionChange",
        ]),
      }),
      step: z.literal(RunStage.Failed),
    }),
    z.object({
      step: z.literal(RunStage.Loaded),
    }),
    z.object({
      endTime: z.date(),
      step: z.literal(RunStage.Completed),
    }),
    z.object({
      step: z.literal(RunStage.Washing),
      washStep: z.nativeEnum(Status_MachineWash_Type),
    }),
    z.object({
      details: z.object({
        currentPhaseIndex: z.number(),
        phases: z.array(statusPhaseSchema),
        startTime: z.date(),
      }),
      step: z.literal(RunStage.Printing),
    }),
  ]),
  warnings: z.array(z.object({ description: z.string() })),
});
export type RunProgress = z.infer<typeof runProgressSchema>;
export type RunPhase = Exclude<RunProgress["initialPhases"], null>[0];

export enum ModificationStatus {
  Acceptable = "Acceptable",
  Low = "Low",
  NonQuantifiable = "NonQuantifiable",
  None = "None",
  Successful = "Successful",
}
